// extracted by mini-css-extract-plugin
export var body = "leftRight-module--body--ed0a9";
export var colSpan10 = "leftRight-module--colSpan10--bfbd4";
export var colSpan2 = "leftRight-module--colSpan2--586de";
export var colSpan3 = "leftRight-module--colSpan3--8b4ea";
export var colSpan4 = "leftRight-module--colSpan4--bd1e3";
export var colSpan5 = "leftRight-module--colSpan5--12d80";
export var colSpan6 = "leftRight-module--colSpan6--e9a08";
export var colSpan7 = "leftRight-module--colSpan7--22e89";
export var colSpan8 = "leftRight-module--colSpan8--7ffa2";
export var colSpan9 = "leftRight-module--colSpan9--8d1be";
export var content = "leftRight-module--content--04570";
export var contentWrap = "leftRight-module--contentWrap--aba5b";
export var ctaSection = "leftRight-module--ctaSection--48c49";
export var gridWrapper = "leftRight-module--gridWrapper--10577";
export var heading = "leftRight-module--heading--bcd92";
export var img = "leftRight-module--img--87262";
export var leftRightModule = "leftRight-module--leftRightModule--292ff";
export var media = "leftRight-module--media--b8a62";
export var textAlignCenter = "leftRight-module--textAlignCenter--926f4";
export var textAlignLeft = "leftRight-module--textAlignLeft--73f0a";
export var textAlignRight = "leftRight-module--textAlignRight--41f5a";
export var valignBottom = "leftRight-module--valignBottom--35215";
export var valignCenter = "leftRight-module--valignCenter--74f4f";
export var valignTop = "leftRight-module--valignTop--d93ed";
export var video = "leftRight-module--video--06712";