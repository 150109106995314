import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Video from "@v4/gatsby-theme-talend/src/components/Video/Video";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import stylingOptions from "@v4/utils/stylingOptions";
import getTextAlignClassName from "@v4/utils/getTextAlignClassName";
import camelcase from "lodash.camelcase";

export const fragment = graphql`
    fragment LeftRight on ContentfulLeftRight {
        id
        name
        headingTextShade
        headingAlignment
        heading {
            raw
            references {
                ...RTEReferences
            }
        }
        bodyTextShade
        bodyAlignment
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        cta {
            ...Cta
        }
        LeftRightImage: image {
            width
            height
            public_id
            format
        }
        imageAltText
        fullBleed
        LeftRightVideo: video
        columnSplit
        contentOrder
        contentVertAlign
        stylingOptions {
            ...stylingOptions
        }
    }
`;

// The main component
const LeftRight = ({ CTA, formatContentfulRichText, cx, ...props }) => {
    const styleOpts = stylingOptions(props.stylingOptions, cx);

    // Heading & Body alignment options for cx classes:
    const hAlign = getTextAlignClassName(props.headingAlignment);
    const bAlign = getTextAlignClassName(props.bodyAlignment);

    // Text shade options for cx classes:
    const hShade = props.headingTextShade ? "textShade" + props.headingTextShade : null;
    const bShade = props.bodyTextShade ? "textShade" + props.bodyTextShade : null;

    // Get content for Heading and Body, integrate alignment options
    const heading =
        props.heading && props.heading.raw ? (
            <div className={`${cx("heading", hAlign, hShade)}`}>{renderRichText(props.heading)}</div>
        ) : null;
    const body =
        props.body && props.body.raw ? (
            <div className={cx("body", bAlign, bShade)}>{renderRichText(props.body, formatContentfulRichText())}</div>
        ) : null;

    // Set up image and video
    const image = props.LeftRightImage ? props.LeftRightImage[0] : false;
    const video = props.LeftRightVideo ?? false;

    // Column Order values
    const contentOrder = (props.contentOrder ? props.contentOrder : "Text/Media").toLowerCase();
    // set reverseCols to true if contentOrder starts with "media"
    const reverseCols = Boolean(contentOrder.toLowerCase().indexOf("media") === 0);

    // Column Styles
    const [leftCol, rightCol] = (props.columnSplit ? props.columnSplit : "6/6").split("/");
    const colStyles = {
        left: `colSpan${leftCol}`,
        right: `colSpan${rightCol}`,
    };

    // Content Vertical Alignment
    const verticalAlign = props.contentVertAlign ? `valign${props.contentVertAlign}` : null;

    // "Full bleed" option - intentionally adding "-" character for camelcase() func
    const fullBleedClass = props.fullBleed?.length
        ? `${camelcase(`negative-${styleOpts.options.spacingTop}`)} ${camelcase(
              `negative-${styleOpts.options.spacingBottom}`
          )}`
        : null;

    // Making the Media column its own component, because it feels easier to reverse the order this way
    const mediaEl = (
        <div className={cx("media", reverseCols ? colStyles.left : colStyles.right, verticalAlign, fullBleedClass)}>
            {video ? (
                <Video videoSrcURL={video} videoTitle={heading} customClass={cx("video")} />
            ) : image ? (
                <Image
                    className={cx("img")}
                    image={image}
                    alt={props.imageAltText}
                    style={image && { maxWidth: `${image.width / 2}px` }}
                    width={image.width / 2}
                    height={image.height / 2}
                    sizes={`(max-width: ${image.width / 2}px) 95vw, ${image.width / 2}px`}
                    imgStyle={{ objectFit: "contain" }}
                />
            ) : null}
        </div>
    );

    // Making the Text/Content column its own component, because it feels easier to reverse the order this way
    const textEl = (
        <div className={cx("contentWrap", reverseCols ? colStyles.right : colStyles.left, verticalAlign)}>
            <div className={cx("content")}>
                {heading}
                {body}
                {props.cta ? (
                    <div className={cx(`ctaSection`)}>
                        {props.cta.map((cta, index) => (
                            <CTA key={`cta-${index}`} {...cta} />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );

    return (
        <section
            id={props.moduleId}
            className={`${cx("leftRightModule")} ${styleOpts && styleOpts.classString}`}
            style={styleOpts.bgHex}
        >
            {styleOpts && styleOpts.borderTopEl}
            <div className={cx("gridWrapper")}>
                {reverseCols ? (
                    <>
                        {mediaEl}
                        {textEl}
                    </>
                ) : (
                    <>
                        {textEl}
                        {mediaEl}
                    </>
                )}
            </div>
            {styleOpts && styleOpts.bgImageEl}
            {styleOpts && styleOpts.borderBottomEl}
        </section>
    );
};

export default LeftRight;
